import { Link } from "react-router-dom";

const TourCard = (props) => {
    let night = "";
    console.log(props.timeNights);
    if (props.timeNights > 0) {
        // night = "д" + " " + "/" + " " + props.timeNights + 'н';
        night = `д / ${props.timeNights}н`
    } else {
        // night = " " + "день" + "";
        night = ` день`
    }
    console.log(night);


    return (
        <Link to={`/tours/${props.id}`}>
            <div >
                <div>
                    <div className="tour" >

                        <div className="image" style={{ backgroundImage: `url(${props.src})` }}>
                            <div className="duration">Тривалість: {props.timeTour}{night}</div>
                            <div className="price">Вартість: {props.prise} грн</div>
                        </div>
                        <p className="name">{props.title}</p>
                    </div>
                </div>
            </div >
        </Link>
    )
}
export default TourCard;