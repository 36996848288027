import React, { useState } from 'react';
import axios from 'axios';

function FeedbackForm() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    page: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/send-feedback', formData);

      if (response.status === 200) {
        alert('Сообщение успешно отправлено!');
        setFormData({
          name: '',
          phone: '',
          page: '',
        });
      } else {
        alert('Что-то пошло не так. Пожалуйста, попробуйте еще раз.');
      }
    } catch (error) {
      console.error('Ошибка отправки данных:', error);
      alert('Что-то пошло не так. Пожалуйста, попробуйте еще раз.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Имя:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="phone">Телефон:</label>
        <input
          type="text"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="page">Назва тура:</label>
        <input
          type="text"
          id="page"
          name="page"
          value={formData.page}
          onChange={handleChange}
          required
        />
      </div>
      <button type="submit">Отправить</button>
    </form>
  );
}

export default FeedbackForm;
