
import React from 'react';
import './App.css'
import Header from './component/header/header';
import ListTouristCards from './component/list-of-tourist-cards/list-of-tourist-cards';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DiscriptionTour from './component/tour-description/tour-description';


const App = () => {
  return (
    <div >
      <Header />
      <Router>
        <Routes>
          <Route exact path="/" element={<ListTouristCards />} />
          <Route exact path="/tours/:id" element={<DiscriptionTour />} />

        </Routes>

      </Router>
    </div>
  );
}

export default App;
