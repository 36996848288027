import React from 'react';
import headerCss from './header.module.scss';
import Logo from '../../images/logo_sm.png';
import logoAdress from '../../images/Icon_Address.png';
import logoTel from '../../images/Icon_Phone.png';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
/* The following line can be included in a src/App.scss */
import 'bootstrap/dist/css/bootstrap.min.css';



const Header = () => {


    return (
        <>
            {/* <header className={headerCss.header}> */}
            {/* <a href={'https://otitour.com/'}><img className={headerCss.logoImg} src={Logo} alt='logo' /></a>
                <nav className={headerCss.haederNav}>
                    <div className={headerCss.sp_column}>
                        <div >
                            <ul className={headerCss.nav_menuTop}>
                                <li class="itemNav"><a href="/pro-nas">Про нас</a></li>
                                <li class="itemNav"><a href="/oplata">Оплата</a></li>
                                <li class="itemNav"><a href="/kontakty">Контакти</a></li>
                                <li class="itemNav"><a href="/strahuvannya">Страхування</a></li>
                            </ul>
                        </div>

                        <div class="sp-module _menu menu_main">
                            <div class="sp-module-content">
                                <ul className={headerCss.nav_menu}>
                                    <li className={headerCss.itemNav2}><a href="/">Головна</a></li>
                                    <li className={headerCss.itemNav2}><a href="https://otitour.com/avtobusnni-tury">Автобусні Тури</a></li>
                                    <li className={headerCss.itemNav2}><a href="/poshuk-turu-otpuskcom">Тури Україною</a></li>
                                    <li className={headerCss.itemNav2}><a href="/pidbir-turu">Підбір туру</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </nav> */}
            <Navbar expand="lg" className={headerCss.bgTop}><span className={headerCss.spanTop}><img className={headerCss.icoTop} src={logoAdress} alt='ico' /> Львів.  Лемківська 16</span><span className={headerCss.spanTop}><a className={headerCss.telTop} href='tel:+380677565876'><img className={headerCss.icoTop} src={logoTel} alt='ico' /> +38(067)756-58-76</a></span></Navbar>
            <Navbar expand="lg" className={headerCss.bgbodytertiary}>
                <Container><a href="https://otitour.com/">
                    <Image src={Logo} className={headerCss.navImg} />
                    <div className={headerCss.brand}>Туристична агенція Ольги Сороки</div>
                </a>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className={headerCss.collapse}>
                        <Nav className="me-auto">
                            <Nav.Link className={headerCss.navLink} href="https://otitour.com/">Головна</Nav.Link>
                            <Nav.Link className={headerCss.navLink} href="https://tourseurope.otitour.com/">Автобусні тури</Nav.Link>
                            <Nav.Link className={headerCss.navLink} href="https://turyukr.com/">Тури Україною</Nav.Link>
                            <Nav.Link className={headerCss.navLink} href="https://otitour.com/pidbir-turu">Підбір туру</Nav.Link>
                            <NavDropdown title="Меню" id="basic-nav-dropdown" className={headerCss.navDropdown}>
                                <NavDropdown.Item href="https://otitour.com/pro-nas">Про нас</NavDropdown.Item>
                                <NavDropdown.Item href="https://otitour.com/kontakty">
                                    Контакти
                                </NavDropdown.Item>
                                <NavDropdown.Item href="https://otitour.com/oplata">Оплата</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="https://otitour.com/strahuvannya">
                                    Страхування
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* </header> */}
        </>
    )
}

export default Header;